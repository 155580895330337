import { getPhoneCountryCode } from "./get-phone-country-code";
import { getPhoneMaskList } from "./get-phone-mask";

export const isPhoneValid = (phone: string | null | undefined): boolean => {
  const masks = getPhoneMaskList(phone ?? "");
  const minLength = Math.min(
    ...masks.map((mask) => mask.replace(/[^#]/g, "").length)
  );
  const phoneLength = phone?.replace(/\D/g, "")?.length ?? 0;

  return phoneLength >= minLength && !!getPhoneCountryCode(phone ?? "");
};
